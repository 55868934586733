import {
  elasticServiceRequest,
  requestApi
} from '../../services/HttpRequester';
import { ENDPOINTS } from '../../constants/endpoints';
import { formatToYearMonthDay } from '../../utils/format.dates.utils';

export function exportFile(event) {
  return requestApi({
    method: 'GET',
    url: `usage/export/${event}`,
    responseType: 'blob'
  });
}

export async function exportElasticServiceFile(path) {
  return elasticServiceRequest({
    method: 'GET',
    path: path,
    responseType: 'blob',
    headers: {
      'Access-Control-Expose-Headers': 'File-Name'
    }
  });
}

export async function readTable(path) {
  return elasticServiceRequest({
    method: 'GET',
    path: path
  });
}

export async function readUsageDates() {
  return elasticServiceRequest({
    method: 'GET',
    path: ENDPOINTS.GET_USAGE_DATES
  });
}

// Data Cards//
export async function readUsageDataCards(date) {
  const startTime = formatToYearMonthDay(date?.[0]);
  const endTime = formatToYearMonthDay(date?.[1]);
  return elasticServiceRequest({
    method: 'GET',
    path: ENDPOINTS.GET_USAGE_DATA_CARDS.replace(
      ':startTime:',
      startTime
    ).replace(':endTime:', endTime)
  });
}

// Data Cards Details//
export async function readUsageDataCardsDetails(date) {
  const endTime = formatToYearMonthDay(date?.[1]);
  return elasticServiceRequest({
    method: 'GET',
    path: ENDPOINTS.GET_USAGE_DATA_CARDS_DETAILS.replace(':endTime:', endTime)
  });
}

// USAGE DETAILS //
export async function readUsageDetailsTable(path) {
  return elasticServiceRequest({
    method: 'GET',
    path: path
  });
}

export async function exportElasticServiceDetailsFile(path) {
  return elasticServiceRequest({
    method: 'GET',
    path: path,
    responseType: 'blob'
  });
}

export async function readUsageDetailsFilters(filter, page, size, search) {
  return elasticServiceRequest({
    method: 'GET',
    path: ENDPOINTS.USAGES_DETAILS_FILTERS(filter)
      .replace(':page:', page)
      .replace(':size:', size)
      .concat(search?.length > 0 ? '&search=' + search : '')
  });
}

export async function readFiltersWith(url, filter) {
  return elasticServiceRequest({
    method: 'GET',
    path: url + filter
  });
}

// Get RCS usage details //
export async function getRcsUsageDetails() {
  return elasticServiceRequest({
    method: 'GET',
    path: ENDPOINTS.GET_USAGES_RCS_DETAILS
  });
}
