import { calculateDateDifference } from './format.dates.utils';
import i18n from '../i18n';

export function saveDismissState(id, bannerId) {
  const key = `BANNER_DISMISSED_${id}_${bannerId}`;
  // eslint-disable-next-line no-restricted-globals
  localStorage.setItem(key, 'true');
}

export function isBannerDismissed(id, bannerId) {
  const key = `BANNER_DISMISSED_${id}_${bannerId}`;
  // eslint-disable-next-line no-restricted-globals
  return localStorage.getItem(key) === 'true';
}

export function shouldShowBanner(id, bannerId) {
  return !isBannerDismissed(id, bannerId);
}

export function handleDismiss(id, bannerId) {
  saveDismissState(id, bannerId);
  return false;
}

export const calculateRemainingTimeText = (contractEndDate, dateService) => {
  if (!contractEndDate) return null;

  const currentDate = new Date();
  const endDate = new Date(contractEndDate);
  const dateDifference = calculateDateDifference(currentDate, endDate);

  if (!dateDifference) return null;

  const { years, months, days } = dateDifference;
  const timeParts = [];

  if (years > 0) {
    timeParts.push(`${years} years`);
  }
  if (months > 0) {
    timeParts.push(`${months} months`);
  }
  if (days > 0) {
    timeParts.push(`${days} days`);
  }

  return timeParts.length > 0 ? timeParts.join(' and ') : null;
};

export const calculateBannerDetails = data => {
  const percentageUsed =
    data.usedQuantity && data.totalCapacity
      ? Math.round((data.usedQuantity / data.totalCapacity) * 100)
      : 0;

  const remainingTimeText = calculateRemainingTimeText(data.contractEndDate);

  if (percentageUsed >= 90) {
    return {
      isBannerVisible: true,
      isCloseIconVisible: false,
      bannerType: 'danger',
      bannerText: remainingTimeText
        ? i18n.t('storageDangerText', { percentageUsed, remainingTimeText })
        : i18n.t('storageDangerTextNoTime', { percentageUsed })
    };
  } else if (percentageUsed >= 50) {
    return {
      isBannerVisible: true,
      isCloseIconVisible: true,
      bannerType: 'warning',
      bannerText: remainingTimeText
        ? i18n.t('storageWarningText', { percentageUsed, remainingTimeText })
        : i18n.t('storageWarningTextNoTime', { percentageUsed })
    };
  } else {
    return { isBannerVisible: false };
  }
};
