/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import CloseModalIcon from '../../../assets/icons/x.svg';

import { CloseModalImg, ModalBasic, ModalBody, ModalHeader } from './Modal';
import Title from '../../common/title/Title';
import { colors } from '../../constant';

export function QuotaExceededModal({ open, close }) {
  const { t } = useTranslation();

  return (
    <ModalBasic style={{ width: '400px' }} open={open} closeModal={close}>
      <div>
        <ModalHeader>
          <Title color={colors.black} fontSize="16px" fontWeight="500">
            {t('quotaExceededTitle')}
          </Title>
          <CloseModalImg src={CloseModalIcon} onClick={close} />
        </ModalHeader>

        <ModalBody>
          <p>{t('quotaExceededMessage')}</p>
        </ModalBody>
      </div>
    </ModalBasic>
  );
}

QuotaExceededModal.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired
};
