import {
  elasticServiceRequest,
  requestApi
} from '../../services/HttpRequester';

import { ENDPOINTS } from '../../constants/endpoints';

export function getAllBuckets() {
  return requestApi({
    method: 'GET',
    url: '/buckets',
    redirectError: '/buckets'
  });
}

export function deleteBucket(name) {
  return requestApi({
    method: 'DELETE',
    url: '/buckets/'.concat(name)
  });
}

export function getAllBucketsNames() {
  return requestApi({
    method: 'GET',
    url: '/buckets/names'
  });
}

export function bucketExists(name) {
  return requestApi({
    method: 'GET',
    url: '/buckets/'.concat(name).concat('/exists')
  });
}

export function getAllBucketObjects(
  bucketName,
  directory = '',
  continuationToken = '',
  pageSize = 20,
  search = ''
) {
  const dir = directory.length > 0 ? directory.concat('/') : '';
  let sValue = null;
  if (search?.length > 0) {
    sValue = search;
  }

  let conToken = '';
  if (continuationToken?.length > 0) {
    conToken = encodeURIComponent(continuationToken);
  }

  return requestApi({
    method: 'GET',
    url: '/buckets/'
      .concat(bucketName)
      .concat('/objects?ContinuationToken=')
      .concat(conToken),
    params: {
      size: pageSize,
      dir,
      s: sValue
    },
    redirectError: '/buckets'
  });
}

export function getBucketProperties(bucketName) {
  return requestApi({
    method: 'GET',
    url: `/buckets/${bucketName}/settings `
  });
}

export function getRegions(userId) {
  return requestApi({
    method: 'GET',
    url: '/regions?userId='.concat(userId)
  });
}

export function createBucket(data) {
  return requestApi({
    method: 'POST',
    url: '/buckets',
    data
  });
}

export function updateBucketProperties(name, data) {
  return requestApi({
    method: 'PUT',
    url: `/buckets/${name}/settings/logging`,
    data
  });
}

export function updateObjectLogging(name) {
  return requestApi({
    method: 'PUT',
    url: `/buckets/${name}/settings/logging`,
    data: {
      Enabled: false
    }
  });
}

export function enableBucketVersioning(name, versioning) {
  return requestApi({
    method: 'PUT',
    url: `buckets/${name}/settings/versioning?versioning=${versioning}`
  });
}

export function enableObjectLocking(name, data) {
  return requestApi({
    method: 'PUT',
    url: `buckets/${name}/settings/retention`,
    data
  });
}

export function enableObjectLockingByName(bucketName, objName, payload) {
  const encodedObjName = encodeURIComponent(objName);
  return requestApi({
    method: 'PUT',
    url: `buckets/${bucketName}/objects/locking?objName=${encodedObjName}`,
    data: payload
  });
}

export function toggleLegalHolding(bucketName, objName, payload) {
  const encodedObjName = encodeURIComponent(objName);
  return requestApi({
    method: 'PUT',
    url: `buckets/${bucketName}/objects/legal-hold?objName=${encodedObjName}`,
    data: payload
  });
}

export function toggleShowVersions(bucketName, objName, payload) {
  return requestApi({
    method: 'PUT',
    url: 'versioning'
  });
}

/**
 * Create Folder Endpoint
 *
 * @param fetchData
 * @param bucketName
 * @param folderName Name of the folder. If  nested, folder name should include parent folder name (ex. ParentFolder/FolderToCreate).
 * @returns {*}
 */
export function createFolder(bucketName, folderName, dir) {
  return requestApi({
    method: 'POST',
    url: '/buckets/'.concat(bucketName).concat('/objects'),
    params: {
      folder: dir
        ? dir
            .concat('/')
            .concat(folderName)
            .concat('/')
        : folderName.concat('/'),
      sha256: null
    }
  });
}

export function getObjectDetails(bucketName, objectName, versionId) {
  return requestApi({
    method: 'GET',
    url: '/buckets/'.concat(bucketName).concat('/objects/details'),
    params: {
      objectName: objectName,
      versionId: versionId
    }
  });
}

export function renameFile(
  bucketName,
  dir,
  objName,
  newObjName,
  selectedRowVersionId
) {
  return requestApi({
    method: 'PUT',
    url: '/buckets/'.concat(bucketName).concat('/objects/rename'),
    data: {
      dir: dir,
      objName: objName,
      newObjName: newObjName,
      versionId: selectedRowVersionId
    }
  });
}

export function downloadFile(bucketName, fileName, versionId) {
  return requestApi({
    method: 'GET',
    url: '/buckets/'.concat(bucketName).concat('/objects/download'),
    params: {
      key: fileName,
      versionId: versionId
    }
  });
}

export function deleteBucketObjects(name, selectedRows = [], directory) {
  const objectNames = selectedRows.map(item =>
    directory
      ? { ...item, name: directory.concat('/').concat(item?.name) }
      : item
  );
  const keys = [];

  objectNames.forEach((row, index) => {
    keys.push(encodeURIComponent(row?.name || row));
  });

  return requestApi({
    method: 'DELETE',
    url: '/buckets/'.concat(name).concat('/objects'),
    data: objectNames.map(item => {
      return {
        key: item?.name,
        versionId: item.versionId
      };
    })
  });
}

export function getUploadLinks(name, dir, objName, fileParts, fileMetaData) {
  return requestApi({
    method: 'GET',
    url: '/buckets/'.concat(name).concat('/objects/upload'),
    params: {
      usermetadata: fileMetaData,
      objName: objName,
      dir: dir,
      parts: fileParts > 1 ? fileParts : 0
    }
  });
}

export function completeUploadLinks(
  name,
  fileName,
  fileParts,
  UploadId,
  userMetaData
) {
  return requestApi({
    method: 'PUT',
    url: '/buckets/'.concat(name).concat('/objects/complete-upload'),
    data: {
      UserMetaData: userMetaData,
      Key: fileName,
      Parts: fileParts,
      UploadId: UploadId
    }
  });
}

export async function readTableUsageBucket(bucketName) {
  return elasticServiceRequest({
    method: 'GET',
    path: bucketName
  });
}

export async function readUsageBucketDates(bucketNumber) {
  return elasticServiceRequest({
    method: 'GET',
    path: ENDPOINTS.GET_USAGE_DATES_BUCKET(bucketNumber)
  });
}

export async function readBucketStatus(bucketNumber) {
  return elasticServiceRequest({
    method: 'GET',
    path: ENDPOINTS.GET_BUCKET_STATUS(bucketNumber)
  });
}

// Data Cards//
export async function readUsageBucketDataCards(path) {
  return elasticServiceRequest({
    method: 'GET',
    path: path
  });
}

// Read Sub-Account Status //
export async function readSubAccountStatus() {
  return elasticServiceRequest({
    method: 'GET',
    path: ENDPOINTS.READ_SUB_ACCOUNT_STATUS
  });
}
