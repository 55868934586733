import moment from 'moment';

export const formatToYearMonthDay = date => {
  let formattedDate;

  // Try parsing using moment
  const momentDate = moment(
    date,
    [
      'YYYY-MM-DD',
      'DD-MMM-YYYY',
      'DD-MMM-YYYY hh:mm A',
      'MM/DD/YYYY',
      'MM-DD-YYYY',
      'YYYY/MM/DD',
      'YYYY-MM',
      'YYYY',
      'YYYY-M-D',
      'D-MMM-YY',
      'DD-MMM-YYYY',
      'YYYY-MM-DD HH:mm'
    ],
    true
  );
  if (momentDate.isValid()) {
    formattedDate = momentDate.format('YYYY-MM-DD');
  } else {
    // Fallback to alternative parsing
    const parsedDate = new Date(date);
    if (!isNaN(parsedDate)) {
      const year = parsedDate.getFullYear();
      const month = String(parsedDate.getMonth() + 1).padStart(2, '0');
      const day = String(parsedDate.getDate()).padStart(2, '0');
      formattedDate = `${year}-${month}-${day}`;
    } else {
      formattedDate = 'Invalid Date';
    }
  }
  return formattedDate;
};

export const formatDateWithUtc = dateString => {
  const formattedDate = moment(dateString).format('DD-MMM-YYYY hh:mm A');
  const offset = moment(dateString).utcOffset();
  const hours = Math.floor(offset / 60);
  const minutes = Math.abs(offset % 60);
  const offsetString = `${hours >= 0 ? '+' : '-'}${Math.abs(hours)
    .toString()
    .padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
  return `${formattedDate} (UTC${offsetString})`;
};

export const checkTimezoneOffset = dateProp => {
  const date = new Date(dateProp);

  if (date.getTimezoneOffset() > 0) {
    date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
  } else {
    date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
  }
  return date;
};

export const substractDayIfIUTC = dateProp => {
  let date = new Date(dateProp);

  if (date.getTimezoneOffset() > 0) {
    date = moment(date).subtract(1, 'day');
  }

  return date;
};

export const formatToYearMonthDayWithTimeZoneOffset = dateProp => {
  let formattedDate;

  const anotherLanguageDate = moment(dateProp, 'DD-MMM-YYYY hh:mm A').isValid();

  const date = anotherLanguageDate
    ? checkTimezoneOffset(moment(dateProp, 'DD-MMM-YYYY hh:mm A'))
    : checkTimezoneOffset(dateProp);

  // Try parsing using moment
  const momentDate = moment(
    date,
    [
      'YYYY-MM-DD',
      'DD-MMM-YYYY',
      'DD-MMM-YYYY hh:mm A',
      'MM/DD/YYYY',
      'MM-DD-YYYY',
      'YYYY/MM/DD',
      'YYYY-MM',
      'YYYY',
      'YYYY-M-D',
      'D-MMM-YY',
      'DD-MMM-YYYY',
      'YYYY-MM-DD HH:mm'
    ],
    true
  );
  if (momentDate.isValid()) {
    formattedDate = momentDate.format('YYYY-MM-DD');
  } else {
    // Fallback to alternative parsing
    const parsedDate = new Date(date);
    if (!isNaN(parsedDate)) {
      const year = parsedDate.getFullYear();
      const month = String(parsedDate.getMonth() + 1).padStart(2, '0');
      const day = String(parsedDate.getDate()).padStart(2, '0');
      formattedDate = `${year}-${month}-${day}`;
    } else {
      formattedDate = 'Invalid Date';
    }
  }
  return formattedDate;
};

export const calculateDateDifference = (currentDate, endDate) => {
  const diffTime = Math.abs(endDate - currentDate);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  const years = Math.floor(diffDays / 365);
  const months = Math.floor((diffDays % 365) / 30);
  const days = diffDays % 30;

  return { years, months, days };
};
